//全局配置变量
// 路由映射
const WEBSAME_URLS = {
        '/pay': '/pay',
        '/r':'/vip/recommend',
        '/login':'/outer/login'
    }
// 微信跳转排除
const UNWANTED_URLS = ['/paystatus.*','/help/.*']
export {
    WEBSAME_URLS,
    UNWANTED_URLS
}
